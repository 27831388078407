import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { ApplicationContext } from '../../common/ApplicationContext';
import { CommonInformation } from '../../common/models/CommonInformation';
import LocaleUtils from '../../utils/LocaleUtils';
import CommonInformationData from '../../assets/data/commonInformation.json';

type ContactProps = {};

export default function Contact(props: ContactProps) {
    const appContext = useContext(ApplicationContext);
    const commonInformation: CommonInformation = CommonInformationData.data;
    const pageTitle = LocaleUtils.localeOption('pages.contact.title', appContext.locale);
    const headerTitle = `${commonInformation.companyName} - ${pageTitle}`;

    return (
        <>
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>
            <div className="content-section">
                <h1>{pageTitle}</h1>
                <table style={{border: 'none'}} className={'app-right-padded-table'}>
                    <tbody>
                    <tr>
                        <td>{LocaleUtils.localeOption('pages.contact.supportLabel', appContext.locale)}</td>
                        <td>
                            <a className="ulink" href="mailto:support@lcpsoft.com" target="_blank" rel="noopener noreferrer">support@lcpsoft.com</a>
                        </td>
                    </tr>
                    <tr>
                        <td>{LocaleUtils.localeOption('pages.contact.salesLabel', appContext.locale)}</td>
                        <td>
                            <a className="ulink" href="mailto:sales@lcpsoft.com" target="_blank" rel="noopener noreferrer">sales@lcpsoft.com</a>
                        </td>
                    </tr>
                    <tr>
                        <td>{LocaleUtils.localeOption('pages.contact.infoLabel', appContext.locale)}</td>
                        <td>
                            <a className="ulink" href="mailto:info@lcpsoft.com" target="_blank" rel="noopener noreferrer">info@lcpsoft.com</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
        );
}
