import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import { VersionHistoryItem } from '../../common/models/VersionHistoryItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import VersionHistoryData from '../../assets/data/versionHistory.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type VersionHistoryProps = {
    params: any;
};

function VersionHistory(props: VersionHistoryProps) {
    const appContext = useContext(ApplicationContext);
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const versionHistoryData: { [index: string]: any } = VersionHistoryData;
    const versionHistoryItem: VersionHistoryItem = versionHistoryData[camelCasedProduct];
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];
    const pageTitle = LocaleUtils.localeOption('pages.versionHistory.title', appContext.locale);

    return (
        (versionHistoryItem && productInformationItem) ?
            <>
                <Helmet>
                    <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
                </Helmet>
                <div className="content-section">
                    <h1>{pageTitle}</h1>
                    <ReactMarkdown>
                        {LocaleUtils.localeOption(versionHistoryItem.textKey, appContext.locale)}
                    </ReactMarkdown>
                </div>
            </> : <PageNotFound/>
    );
}

export default ReactUtils.withParams(VersionHistory);
