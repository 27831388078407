import React from 'react';

export default class TableUtils {
    static getRows<T>(items: T[], rowSize: number, getRow: (items: T[], index: number) => React.ReactNode): React.ReactNode {
        const rowCount = Math.ceil(items.length / rowSize);
        const rowItems: T[][] = [];

        for (let i = 0; i < rowCount; i++) {
            const startIndex = i * rowSize;
            const endIndex = Math.min((i + 1) * rowSize, items.length);

            rowItems.push(items.slice(startIndex, endIndex));
        }

        return (
            <>
                {rowItems.map((value: T[], index: number) => getRow(value, index))}
            </>
        );
    }
}
