import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { ApplicationContext } from '../../../common/ApplicationContext';
import { CommonInformation } from '../../../common/models/CommonInformation';
import LocaleUtils from '../../../utils/LocaleUtils';
import CommonInformationData from '../../../assets/data/commonInformation.json';

type ActivationOrRenewalProps = {
    titleKey: string;
    labelKey: string;
    buttonTextKey: string;
    errorMessageKey: string;
    action: (licenseInfo: string, catchCallback: (error: AxiosError) => void) => void;
};

export default function ActivationOrRenewal(props: ActivationOrRenewalProps) {
    const appContext = useContext(ApplicationContext);
    const [licenseInfo, setLicenseInfo] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const commonInformation: CommonInformation = CommonInformationData.data;
    const pageTitle = LocaleUtils.localeOption(props.titleKey, appContext.locale);
    const headerTitle = `${commonInformation.companyName} - ${pageTitle}`;

    const isButtonDisable = () => {
        return !licenseInfo || (licenseInfo.length === 0);
    };

    const dialogFooterContent = (
        <div>
            <Button label="OK" onClick={() => setDialogVisible(false)} autoFocus/>
        </div>
    );

    const getErrorMessage = (error: AxiosError) => {
        const messageTemplate = LocaleUtils.localeOption(props.errorMessageKey, appContext.locale);
        const cause = error?.response?.status ? `${error.response.status}` : 'Unknown Error';

        return LocaleUtils.formatString(messageTemplate, {'message': cause});
    };

    const handleError = (error: AxiosError) => {
        setErrorMessage(getErrorMessage(error));
        setDialogVisible(true);
    };

    const handleClick = () => {
        props.action(licenseInfo, handleError);
    };

    return (
        <>
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>
            <div className="content-section">
                <Dialog header={LocaleUtils.localeOption(props.titleKey, appContext.locale)}
                        visible={dialogVisible} style={{width: '50vw'}} onHide={() => setDialogVisible(false)}
                        footer={dialogFooterContent} className="p-error-dialog">
                    <i className="pi pi-exclamation-triangle p-error-dialog-icon"></i>
                    <span className="p-error-dialog-message">{errorMessage}</span>
                </Dialog>

                <h1>{pageTitle}</h1>
                <div className="field">
                    <label htmlFor="license-info">
                        {LocaleUtils.localeOption(props.labelKey, appContext.locale)}
                    </label>
                    <InputTextarea id="license-info" value={licenseInfo}
                                   onChange={(e) => setLicenseInfo(e.target.value)}
                                   rows={10} className="w-full"/>
                </div>
                <div className="text-center">
                    <Button label={LocaleUtils.localeOption(props.buttonTextKey, appContext.locale)}
                            onClick={handleClick} disabled={isButtonDisable()}/>
                </div>
            </div>
        </>
    );
}
