import { useState } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { Footer } from './Footer';
import Menu from './Menu';
import Topbar from './Topbar';
import { ApplicationContext } from '../common/ApplicationContext';
import { classNames } from '../utils/ClassNames';
import LocaleUtils from '../utils/LocaleUtils';

type LayoutProps = {
    children: React.ReactNode;
};

export default function Layout(props: LayoutProps) {
    const [sidebarActive, setSidebarActive] = useState(false);
    const matchPathResult = useMatch({path: '/:locale', end: false});
    const pathLocale = matchPathResult?.params['locale'];
    const [locale, setLocale] = useState(LocaleUtils.getInitialLocale(pathLocale));
    const location = useLocation();
    const wrapperClassName = 'layout-wrapper layout-wrapper-light';
    const maskClassName = classNames('layout-mask', {
        'layout-mask-active': sidebarActive
    });

    LocaleUtils.localeIfNeeded(locale);

    const onMenuButtonClick = () => {
        setSidebarActive(true);
    };

    const onMenuItemClick = () => {
        setSidebarActive(false);
    };

    const onMaskClick = () => {
        setSidebarActive(false);
    };

    const onLocaleChange = (locale: string) => {
        setLocale(locale);

        // Set new locale
        LocaleUtils.locale(locale);
    }

    const getRedirectTo = () => {
        const defaultLocale = LocaleUtils.getDefaultLocale();

        return location.pathname.replace(new RegExp(`^/${pathLocale}`), `/${defaultLocale}`);
    }

    return (
        <>
            {(pathLocale && !LocaleUtils.isLocaleValid(pathLocale)) ?
                <Navigate to={getRedirectTo()} replace/> :
                <ApplicationContext.Provider value={{locale: locale, onLocaleChange: onLocaleChange}}>
                    <div className={wrapperClassName}>
                        <Topbar onMenuButtonClick={onMenuButtonClick}></Topbar>
                        <Menu active={sidebarActive} onMenuItemClick={onMenuItemClick}></Menu>
                        <div className="layout-content">
                            <div className="layout-content-inner">
                                {props.children}
                                <Footer></Footer>
                            </div>
                        </div>
                        <div className={maskClassName} onClick={onMaskClick}></div>
                    </div>
                </ApplicationContext.Provider>
            }
        </>
    );
}
