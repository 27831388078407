import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { ApplicationContext } from '../../common/ApplicationContext';
import { ApplicationItem } from '../../common/models/ApplicationItem';
import { CommonInformation } from '../../common/models/CommonInformation';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import TableUtils from '../../utils/TableUtils';
import LocaleUtils from '../../utils/LocaleUtils';
import ApplicationsData from '../../assets/data/applications.json';
import CommonInformationData from '../../assets/data/commonInformation.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type MainProps = {};

export default function Main(props: MainProps) {
    const contextPath = process.env.PUBLIC_URL;
    const rowSize = 2;
    const appContext = useContext(ApplicationContext);
    const commonInformation: CommonInformation = CommonInformationData.data;
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const headerTitle = commonInformation.companyName;

    const getCell = (item: ApplicationItem, index: number) => {
        const productInformationItem: ProductInformationItem = productInformationData[item.product];
        const to = `/${appContext.locale}/${item.to}`;

        return (
            <React.Fragment key={index}>
                <td>
                    <Link to={to}>
                        <img src={`${contextPath}/images/logo/${item.imageName}`} alt={productInformationItem.productName}/>
                    </Link>
                </td>
                <td>
                    <span className="app-application">{productInformationItem.productName}</span>
                    <br/>
                    <Link to={to}>
                        <span className="app-more">{LocaleUtils.localeOption('pages.main.products.moreLabel', appContext.locale)}</span>
                    </Link>
                </td>
            </React.Fragment>
        );
    }

    const getRow = (items: ApplicationItem[], index: number) => {
        return (
            <tr key={index}>
                {items.map((value, index) => getCell(value, index))}
            </tr>
        );
    }

    return (
        <>
            <Helmet>
                <title>{headerTitle}</title>
                <meta name="keywords" content={LocaleUtils.localeOption('pages.main.headerKeywords', appContext.locale)}/>
            </Helmet>
            <div className="content-section">
                <section>
                    <h2>{LocaleUtils.localeOption('pages.main.news.title', appContext.locale)}</h2>
                    <ReactMarkdown>
                        {LocaleUtils.localeOption('pages.main.news.text', appContext.locale)}
                    </ReactMarkdown>
                </section>
                <section>
                    <h2>{LocaleUtils.localeOption('pages.main.products.title', appContext.locale)}</h2>
                    <ReactMarkdown>
                        {LocaleUtils.localeOption('pages.main.products.text', appContext.locale)}
                    </ReactMarkdown>
                    <table className={'app-icon-table'}>
                        <tbody>
                        {TableUtils.getRows(ApplicationsData.data, rowSize, getRow)}
                        </tbody>
                    </table>
                    <img src={`${contextPath}/images/lcpsoft-applications-middle.jpg`} className="app-applications-image" alt="Main Window"/>
                </section>
            </div>
        </>
    );
}
