import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import MenuData from '../assets/data/menu.json';
import { MenuItem } from '../common/models/MenuItem';
import { ApplicationContext } from '../common/ApplicationContext';
import { classNames } from '../utils/ClassNames';
import LocaleUtils from '../utils/LocaleUtils';

type MenuProps = {
    active: boolean;
    onMenuItemClick: () => void;
};

export default function Menu(props: MenuProps) {
    const [filteredMenu] = useState(MenuData.data || []);
    const [activeSubmenus, setActiveSubmenus] = useState<{ [index: string]: any }>({});
    const location = useLocation();
    const appContext = useContext(ApplicationContext);
    const contextPath = process.env.PUBLIC_URL;

    const toggleSubmenu = (name: string) => {
        const _activeSubmenus = {...activeSubmenus};

        _activeSubmenus[name] = !_activeSubmenus[name];
        setActiveSubmenus(_activeSubmenus);
    };

    const setActiveSubmenu = (name: string, value: boolean) => {
        const _activeSubmenus = {...activeSubmenus};

        _activeSubmenus[name] = value;
        setActiveSubmenus(_activeSubmenus);
    };

    const isSubmenuActive = (name: string) => {
        if (activeSubmenus.hasOwnProperty(name)) {
            return activeSubmenus[name];
        }

        return false;
    };

    const getMenuItemText = (item: MenuItem) => {
        const {name, nameKey} = item;

        return (nameKey) ? LocaleUtils.localeOption(nameKey, appContext.locale) : name;
    };

    const renderLink = (item: MenuItem, linkProps: any) => {
        const {to, href} = item;
        const contentText = getMenuItemText(item);
        const content = (
            <>
                {contentText}
            </>
        );

        if (href) {
            return (
                <a href={href} role="menuitem" target="_blank" rel="noopener noreferrer"
                   onClick={props.onMenuItemClick}>
                    {content}
                </a>
            );
        } else if (!to) {
            return (
                <button type="button" className="p-link" {...linkProps}>
                    {content}
                </button>
            );
        }

        const fullTo = `/${appContext.locale}/${to}`;

        return (
            <Link to={fullTo}
                  className={(fullTo === location.pathname) ? 'router-link-exact-active router-link-active' : undefined}
                  onClick={props.onMenuItemClick}>
                {content}
            </Link>
        );
    };

    const renderCategorySubmenuItems = (item: MenuItem, submenuKey: string) => {
        const cSubmenuRef = React.createRef<HTMLDivElement>();

        return (
            <CSSTransition nodeRef={cSubmenuRef} classNames="p-toggleable-content" timeout={{enter: 1000, exit: 450}}
                           in={isSubmenuActive(item.name) || item.expanded} unmountOnExit>
                <div ref={cSubmenuRef} className="p-toggleable-content">
                    <ul role="menu">
                        {(item.children) && item.children.map((item, index) => {
                            const link = renderLink(item, {});

                            return (
                                <li role="menuitem" key={`menuitem_${submenuKey}_${index}`}>
                                    {link}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </CSSTransition>
        );
    };

    const renderCategoryItem = (menuitem: MenuItem, menuitemIndex: number) => {
        if (menuitem.children) {
            return (
                <>
                    {menuitem.children.map((item, index) => {
                        const submenuKey = `${menuitemIndex}_${index}`;
                        const link = renderLink(item, {onClick: () => toggleSubmenu(item.name)});

                        return (
                            <React.Fragment key={`menuitem_${submenuKey}`}>
                                {link}
                                {item.children && renderCategorySubmenuItems(item, submenuKey)}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }

        return null;
    };

    const renderMenu = () => {
        return (
            <>
                {filteredMenu.map((menuitem: MenuItem, index: number) => {
                    const categoryItem = renderCategoryItem(menuitem, index);

                    return (
                        <React.Fragment key={`category_${index}`}>
                            <div className="menu-category">
                                {getMenuItemText(menuitem)}
                            </div>
                            {menuitem.children && <div className="menu-items">{categoryItem}</div>}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    const openActiveItemSubmenu = () => {
        filteredMenu.forEach((item: MenuItem) => {
            if (item.children) {
                item.children.forEach((subitem: MenuItem) => {
                    if (subitem.children && subitem.children.some(subsubitem => `/${appContext.locale}/${subsubitem.to}` === location.pathname)) {
                        setActiveSubmenu(subitem.name, true);
                    }
                })
            }
        });
    };

    useEffect(() => {
        openActiveItemSubmenu();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const menuItems = renderMenu();
    const sidebarClassName = classNames('layout-sidebar', {active: props.active});

    return (
        <div className={sidebarClassName} role="navigation">
            <Link to={`${appContext.locale}/main`} className="logo">
                <img alt="logo" src={`${contextPath}/images/lcpsoft-logo.png`}/>
            </Link>
            <div className="layout-menu" role="menubar">
                {menuItems}
            </div>
        </div>
    );
}
