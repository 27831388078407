import { useContext } from 'react';
import { ApplicationContext } from './ApplicationContext';
import LocaleUtils from '../utils/LocaleUtils';

export default function PageNotFound() {
    const appContext = useContext(ApplicationContext);

    return (
        <div className="content-section">
            <h1>{LocaleUtils.localeOption('pages.pageNotFoundText.title', appContext.locale)}</h1>
        </div>
    );
}
