import React from 'react';
import { useInjection } from 'inversify-react';
import { LicenseService } from '../../../common/services/LicenseService';
import ActivationOrRenewal from './ActivationOrRenewal';

export default function SubscriptionWebRenewal() {
    const licenseService = useInjection(LicenseService);

    return (
        <ActivationOrRenewal titleKey='pages.subscriptionRenewal.title'
                             labelKey='pages.licenseActivation.licenseActivationRequestLabel'
                             buttonTextKey='pages.subscriptionRenewal.renewButtonText'
                             errorMessageKey='pages.subscriptionRenewal.modificationResultError'
                             action={(licenseInfo, handleError) => licenseService.renewSubscription(licenseInfo, handleError)}/>
    );
}
