import { Component } from 'react';
import { CommonInformation } from '../common/models/CommonInformation';
import CommonInformationData from '../assets/data/commonInformation.json';


type FooterProps = {};

type FooterState = {};

export class Footer extends Component<FooterProps, FooterState> {
    render() {
        const contextPath = process.env.PUBLIC_URL;
        const commonInformation: CommonInformation = CommonInformationData.data;

        return (
            <div className="layout-footer">
                <div className="layout-footer-left">
                    &copy; {commonInformation.copyrightDates} {commonInformation.companyName}
                </div>

                <div className="layout-footer-right">
                    <a href={`${contextPath}/rss.xml`} target="_blank" rel="noopener noreferrer" className="mr-3">
                        <i className="fa-solid fa-rss"></i>
                    </a>
                    <a href="https://www.twitter.com/lcpsoft" target="_blank" rel="noopener noreferrer" className="mr-3">
                        <i className="pi pi-twitter"></i>
                    </a>
                    <a href="https://www.facebook.com/lcpsoft" target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-facebook"></i>
                    </a>
                </div>
            </div>
        );
    }
}
