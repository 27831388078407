import axios, { AxiosError } from 'axios';
import { injectable } from 'inversify';
import fileDownload from 'js-file-download';

@injectable()
export class LicenseService {
    private readonly BASE_URL = 'https://online.license4j.com/e/';
    private readonly CONFIG = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' as 'blob'
    };
    private readonly LICENSE_FILENAME: string = 'license.l4j';

    activateLicense(licenseInfo: string, catchCallback: (error: AxiosError) => void) {
        axios.post(`${this.BASE_URL}/ManualActivationServlet`, {
            linfo: licenseInfo,
            action: 'activate'
        }, this.CONFIG)
            .then(response => {
                fileDownload(response.data, this.LICENSE_FILENAME);
            })
            .catch((error: AxiosError) => {
                catchCallback(error);
            });
    }

    renewSubscription(licenseInfo: string, catchCallback: (error: AxiosError) => void) {
        axios.post(`${this.BASE_URL}/ManualModificationServlet`, {
            linfo: licenseInfo,
            action: 'modify'
        }, this.CONFIG)
            .then(response => {
                fileDownload(response.data, this.LICENSE_FILENAME);
            })
            .catch((error: AxiosError) => {
                catchCallback(error);
            });
    }
}
