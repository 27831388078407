import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { Image } from 'primereact/image';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import { ScreenshotItem } from '../../common/models/ScreenshotItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import ScreenshotsData from '../../assets/data/screenshots.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type ScreenshotsProps = {
    params: any;
};

function Screenshots(props: ScreenshotsProps) {
    const appContext = useContext(ApplicationContext);
    const contextPath = process.env.PUBLIC_URL;
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const screenshotsData: { [index: string]: any } = ScreenshotsData;
    const screenshotItems: ScreenshotItem[] = screenshotsData[camelCasedProduct];
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];
    const pageTitle = LocaleUtils.localeOption('pages.screenshots.title', appContext.locale);

    return (
        (screenshotItems && productInformationItem) ?
            <>
                <Helmet>
                    <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
                </Helmet>
                <div className="content-section">
                    <h1>{pageTitle}</h1>
                    <div className="grid">
                        {screenshotItems.map((item: ScreenshotItem, index: number) => {
                            return (
                                <div className="col-6 md:col-4 text-center" key={index}>
                                    <Image src={`${contextPath}/images/screenshots/${item.smallImageName}`}
                                           zoomSrc={`${contextPath}/images/screenshots/${item.bigImageName}`}
                                           preview/>
                                    <ReactMarkdown className={'app-image-note'}>
                                        {LocaleUtils.localeOption(item.textKey, appContext.locale)}
                                    </ReactMarkdown>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </> : <PageNotFound/>
    );
}

export default ReactUtils.withParams(Screenshots);
