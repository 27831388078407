import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { ApplicationContext } from '../common/ApplicationContext';
import { ApplicationContextType } from '../common/models/ApplicationContextType';
import LocalesData from '../assets/data/locales.json';

type TopbarProps = {
    onMenuButtonClick: () => void;
};

export default function Topbar(props: TopbarProps) {
    const containerElement = useRef<HTMLDivElement>(null);
    const scrollListener = useRef<any>();
    const location = useLocation();
    const navigate = useNavigate();

    const languages = LocalesData.data;

    const bindScrollListener = () => {
        scrollListener.current = () => {
            if (containerElement && containerElement.current) {
                if (window.scrollY > 0) containerElement.current.classList.add('layout-topbar-sticky');
                else containerElement.current.classList.remove('layout-topbar-sticky');
            }
        };

        window.addEventListener('scroll', scrollListener.current);
    };

    const unbindScrollListener = () => {
        if (scrollListener.current) {
            window.removeEventListener('scroll', scrollListener.current);
            scrollListener.current = null;
        }
    };

    useEffect(() => {
        bindScrollListener();

        return function unbind() {
            unbindScrollListener();
        };
    }, []);

    const onMenuButtonClick = () => {
        props.onMenuButtonClick();
    };

    const onLocaleChange = (event: DropdownChangeEvent, appContext: ApplicationContextType) => {
        appContext.onLocaleChange(event.value);

        const to = location.pathname.replace(/^\/[A-Za-z]+\//, `/${event.value}/`);
        navigate(to);
    };

    return (
        <ApplicationContext.Consumer>
            {appContext => (
                <div ref={containerElement} className="layout-topbar">
                    <button type="button" className="p-link menu-button" onClick={onMenuButtonClick} aria-haspopup aria-label="Menu">
                        <i className="pi pi-bars"></i>
                    </button>
                    <Dropdown value={appContext.locale} options={languages} optionLabel="label" optionValue="value"
                              onChange={e => onLocaleChange(e, appContext)} className="app-language"/>
                </div>
            )}
        </ApplicationContext.Consumer>
    );
}
