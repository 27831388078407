import { load } from 'js-yaml';
import syncFetch from 'sync-fetch';
import {
    addLocale as primeAddLocale,
    locale as primeLocale,
    localeOption as primeLocaleOption,
    updateLocaleOptions as primeUpdateLocaleOptions
} from 'primereact/api';
import _ from 'lodash';
import dePrimeReactLocales from '../assets/i18n/primelocale/de.json';
import esPrimeReactLocales from '../assets/i18n/primelocale/es.json';
import frPrimeReactLocales from '../assets/i18n/primelocale/fr.json';
import itPrimeReactLocales from '../assets/i18n/primelocale/it.json';
import ptPrimeReactLocales from '../assets/i18n/primelocale/pt.json';
import ruPrimeReactLocales from '../assets/i18n/primelocale/ru.json';

// @ts-ignore
import enApplicationLocalesYaml from '../assets/i18n/application/en.yml';
// @ts-ignore
import deApplicationLocalesYaml from '../assets/i18n/application/de.yml';
// @ts-ignore
import esApplicationLocalesYaml from '../assets/i18n/application/es.yml';
// @ts-ignore
import frApplicationLocalesYaml from '../assets/i18n/application/fr.yml';
// @ts-ignore
import itApplicationLocalesYaml from '../assets/i18n/application/it.yml';
// @ts-ignore
import ptApplicationLocalesYaml from '../assets/i18n/application/pt.yml';
// @ts-ignore
import ruApplicationLocalesYaml from '../assets/i18n/application/ru.yml';

export default class LocaleUtils {
    static readonly EN_LOCALE = 'en';
    static readonly DE_LOCALE = 'de';
    static readonly ES_LOCALE = 'es';
    static readonly FR_LOCALE = 'fr';
    static readonly IT_LOCALE = 'it';
    static readonly PT_LOCALE = 'pt';
    static readonly RU_LOCALE = 'ru';
    static readonly LOCALES = new Set([LocaleUtils.EN_LOCALE, LocaleUtils.DE_LOCALE, LocaleUtils.ES_LOCALE,
        LocaleUtils.FR_LOCALE, LocaleUtils.IT_LOCALE, LocaleUtils.PT_LOCALE, LocaleUtils.RU_LOCALE]);

    static init() {
        // Default locale
        primeLocale(LocaleUtils.getDefaultLocale());

        // Added new locales
        primeAddLocale(LocaleUtils.DE_LOCALE, dePrimeReactLocales[LocaleUtils.DE_LOCALE]);
        primeAddLocale(LocaleUtils.ES_LOCALE, esPrimeReactLocales[LocaleUtils.ES_LOCALE]);
        primeAddLocale(LocaleUtils.FR_LOCALE, frPrimeReactLocales[LocaleUtils.FR_LOCALE]);
        primeAddLocale(LocaleUtils.IT_LOCALE, itPrimeReactLocales[LocaleUtils.IT_LOCALE]);
        primeAddLocale(LocaleUtils.PT_LOCALE, ptPrimeReactLocales[LocaleUtils.PT_LOCALE]);
        primeAddLocale(LocaleUtils.RU_LOCALE, ruPrimeReactLocales[LocaleUtils.RU_LOCALE]);

        // Add multiple options to locales
        primeUpdateLocaleOptions(load(syncFetch(enApplicationLocalesYaml).text()) as object, LocaleUtils.EN_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(deApplicationLocalesYaml).text()) as object, LocaleUtils.DE_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(esApplicationLocalesYaml).text()) as object, LocaleUtils.ES_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(frApplicationLocalesYaml).text()) as object, LocaleUtils.FR_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(itApplicationLocalesYaml).text()) as object, LocaleUtils.IT_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(ptApplicationLocalesYaml).text()) as object, LocaleUtils.PT_LOCALE);
        primeUpdateLocaleOptions(load(syncFetch(ruApplicationLocalesYaml).text()) as object, LocaleUtils.RU_LOCALE);
    }

    static isLocaleValid(locale: string): boolean {
        return LocaleUtils.LOCALES.has(locale);
    }

    static getDefaultLocale(): string {
        return LocaleUtils.EN_LOCALE;
    }

    static getInitialLocale(pathLocale: string | undefined): string {
        if (pathLocale) {
            return LocaleUtils.isLocaleValid(pathLocale) ? pathLocale : LocaleUtils.getDefaultLocale();
        } else {
            return LocaleUtils.getDefaultLocale();
        }
    }

    static locale(locale: string): { locale: string; options: object } {
        return primeLocale(locale);
    }

    static localeIfNeeded(newLocale: string) {
        const {locale} = primeLocale(null!);

        if (locale !== newLocale) {
            primeLocale(newLocale);
        }
    }

    static localeOption(key: string, locale: string): any {
        const subKeys = key.split('.');

        if (subKeys && (subKeys.length > 0)) {
            if (subKeys.length === 1) {
                return primeLocaleOption(subKeys[0], locale);
            } else {
                let subValue = primeLocaleOption(subKeys[0], locale);
                let i = 1;

                while (subValue && (i < subKeys.length)) {
                    subValue = subValue[subKeys[i++]];
                }

                if (subValue) {
                    return subValue;
                }
            }
        }

        throw new Error(`The '${key}' option is not found in the current locale ('${locale}')`);
    }

    static formatString(stringTemplate: string, data: object): string {
        _.templateSettings.interpolate = /{([\s\S]+?)}/g;
        const compiled = _.template(stringTemplate);

        return compiled(data);
    }
}
