import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { ApplicationContext } from '../../../common/ApplicationContext';
import { CommonInformation } from '../../../common/models/CommonInformation';
import LocaleUtils from '../../../utils/LocaleUtils';
import CommonInformationData from '../../../assets/data/commonInformation.json';

type LicenseActivationProps = {};

export default function LicenseActivation(props: LicenseActivationProps) {
    const appContext = useContext(ApplicationContext);
    const commonInformation: CommonInformation = CommonInformationData.data;
    const pageTitle = LocaleUtils.localeOption('pages.licenseActivation.title', appContext.locale);
    const headerTitle = `${commonInformation.companyName} - ${pageTitle}`;

    return (
        <>
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>
            <div className="content-section">
                <h1>{pageTitle}</h1>
                <ReactMarkdown>
                    {LocaleUtils.localeOption('pages.licenseActivation.text', appContext.locale)}
                </ReactMarkdown>
            </div>
        </>
    );
}
