import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import AboutUs from './pages/main/AboutUs';
import Buy from './pages/product/Buy';
import Contact from './pages/main/Contact';
import Download from './pages/product/Download';
import Faq from './pages/product/Faq';
import Layout from './layout/Layout';
import LicenseActivation from './pages/main/license/LicenseActivation';
import LicenseWebActivation from './pages/main/license/LicenseWebActivation';
import Main from './pages/main/Main';
import Overview from './pages/product/Overview';
import QuickStart from './pages/main/QuickStart';
import Screenshots from './pages/product/Screenshots';
import SubscriptionWebRenewal from './pages/main/license/SubscriptionWebRenewal';
import VersionHistory from './pages/product/VersionHistory';
import LocaleUtils from './utils/LocaleUtils';

LocaleUtils.init();
ReactGA.initialize('G-WVHBN45B1E');

function App() {
    const location = useLocation();
    const DEFAULT_LOCATION = '/en/main';

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, [location]);

    return (
        <Layout>
            <Routes>
                <Route path=":locale">
                    <Route index element={<Navigate to="main" replace/>}/>
                    <Route path="main" element={<Main/>}/>
                    <Route path="quick-start" element={<QuickStart/>}/>
                    <Route path="license-activation">
                        <Route index element={<LicenseActivation/>}/>
                        <Route path="license-web-activation" element={<LicenseWebActivation/>}/>
                        <Route path="subscription-web-renewal" element={<SubscriptionWebRenewal/>}/>
                    </Route>
                    <Route path="about-us" element={<AboutUs/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path=":product">
                        <Route index element={<Navigate to={DEFAULT_LOCATION} replace/>}/>
                        <Route path="overview" element={<Overview/>}/>
                        <Route path="version-history" element={<VersionHistory/>}/>
                        <Route path="screenshots" element={<Screenshots/>}/>
                        <Route path="faq" element={<Faq/>}/>
                        <Route path="download" element={<Download/>}/>
                        <Route path="buy" element={<Buy/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to={DEFAULT_LOCATION} replace/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
