import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { HashLink } from 'react-router-hash-link';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { CommonInformation } from '../../common/models/CommonInformation';
import { FaqItem } from '../../common/models/FaqItem';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import CommonInformationData from '../../assets/data/commonInformation.json';
import FaqData from '../../assets/data/faq.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type FaqProps = {
    params: any;
};

function Faq(props: FaqProps) {
    const appContext = useContext(ApplicationContext);
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const commonInformation: CommonInformation = CommonInformationData.data;
    const faqData: { [index: string]: any } = FaqData;
    const faqItems: FaqItem[] = faqData[camelCasedProduct];
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];
    const jrecovererOracledbVersion = productInformationData['jrecovererOracledb']?.productVersion;
    const jrecovererMysqlVersion = productInformationData['jrecovererMysql']?.productVersion;
    const jrecovererPostgresqlVersion = productInformationData['jrecovererPostgresql']?.productVersion;
    const jrecovererMssqlserverVersion = productInformationData['jrecovererMssqlserver']?.productVersion;
    const jrecovererMongodbVersion = productInformationData['jrecovererMongodb']?.productVersion;
    const jrecovererCassandraVersion = productInformationData['jrecovererCassandra']?.productVersion;
    const jrecovererCouchdbVersion = productInformationData['jrecovererCouchdb']?.productVersion;
    const pageTitle = LocaleUtils.localeOption('pages.faq.title', appContext.locale);
    const questionTitleKey = 'questionTitle';
    const answerTextKey = 'answerText';

    const getQuestion = (item: FaqItem) => {
        const questionTemplate = LocaleUtils.localeOption(`${item.faqKey}.${questionTitleKey}`, appContext.locale);
        const question = LocaleUtils.formatString(questionTemplate,
            {
                'applicationName': productInformationItem.applicationName
            });

        return (
            <li key={item.id}>
                <HashLink smooth to={`/${appContext.locale}/${product}/faq#${item.id}`}>{question}</HashLink>
            </li>
        );
    };

    const getQuestions = (items: FaqItem[]) => {
        return (
            <ol>
                {items.map(value => getQuestion(value))}
            </ol>
        );
    };

    const getAnswer = (item: FaqItem, index: number) => {
        const questionTemplate = LocaleUtils.localeOption(`${item.faqKey}.${questionTitleKey}`, appContext.locale);
        const question = LocaleUtils.formatString(questionTemplate,
            {
                'applicationName': productInformationItem.applicationName
            });
        const answerTemplate = LocaleUtils.localeOption(`${item.faqKey}.${answerTextKey}`, appContext.locale);
        const answer = LocaleUtils.formatString(answerTemplate,
            {
                'hardDiskSpace': commonInformation.hardDiskSpace,
                'jreName': commonInformation.jreName,
                'javaDownloadsUrl': commonInformation.javaDownloadsUrl,
                'rainbowTablesApplicationName': commonInformation.rainbowTablesApplicationName,
                'rainbowTablesApplicationSiteUrl': commonInformation.rainbowTablesApplicationSiteUrl,
                'rainbowTablesSiteName': commonInformation.rainbowTablesSiteName,
                'rainbowTablesSiteUrl': commonInformation.rainbowTablesSiteUrl,
                'applicationName': productInformationItem.applicationName,
                'applicationVersions': productInformationItem.applicationVersionsText,
                'applicationHashTypes': productInformationItem.applicationHashTypesText,
                'jrecovererOracledbVersion': jrecovererOracledbVersion,
                'jrecovererMysqlVersion': jrecovererMysqlVersion,
                'jrecovererPostgresqlVersion': jrecovererPostgresqlVersion,
                'jrecovererMssqlserverVersion': jrecovererMssqlserverVersion,
                'jrecovererMongodbVersion': jrecovererMongodbVersion,
                'jrecovererCassandraVersion': jrecovererCassandraVersion,
                'jrecovererCouchdbVersion': jrecovererCouchdbVersion
            });

        return (
            <section id={item.id} key={item.id}>
                <h2>{index + 1}. {question}</h2>
                <ReactMarkdown>{answer}</ReactMarkdown>
            </section>
        );
    };

    const getAnswers = (items: FaqItem[]) => {
        return (
            <>
                {items.map((value, index) => getAnswer(value, index))}
            </>
        );
    };

    return (
        (faqItems && productInformationItem) ?
            <>
                <Helmet>
                    <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
                </Helmet>
                <div className="content-section faq">
                    <h1>{pageTitle}</h1>
                    {getQuestions(faqItems)}
                    {getAnswers(faqItems)}
                </div>
            </> : <PageNotFound/>
    );
}

export default ReactUtils.withParams(Faq);
