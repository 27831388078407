import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { ApplicationContext } from '../../common/ApplicationContext';
import { CommonInformation } from '../../common/models/CommonInformation';
import LocaleUtils from '../../utils/LocaleUtils';
import CommonInformationData from '../../assets/data/commonInformation.json';

type QuickStartProps = {};

export default function QuickStart(props: QuickStartProps) {
    const appContext = useContext(ApplicationContext);
    const commonInformation: CommonInformation = CommonInformationData.data;
    const textTemplate = LocaleUtils.localeOption('pages.quickStart.text', appContext.locale);
    const text = LocaleUtils.formatString(textTemplate,
        {'jreName': commonInformation.jreName, 'javaDownloadsUrl': commonInformation.javaDownloadsUrl});
    const pageTitle = LocaleUtils.localeOption('pages.quickStart.title', appContext.locale);
    const headerTitle = `${commonInformation.companyName} - ${pageTitle}`;

    return (
        <>
            <Helmet>
                <title>{headerTitle}</title>
            </Helmet>
            <div className="content-section">
                <h1>{pageTitle}</h1>
                <ReactMarkdown>{text}</ReactMarkdown>
            </div>
        </>
    );
}
