import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import ProductInformationData from '../../assets/data/productInformation.json';
import ReactMarkdown from "react-markdown";

type BuyProps = {
    params: any;
};

function Buy(props: BuyProps) {
    const appContext = useContext(ApplicationContext);
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];

    const getPrice = (priceTemplate: string, price: number) => {
        return LocaleUtils.formatString(priceTemplate, {'price': price}).replace(/ /g, '\u00A0');
    };

    const getText = (text: string, productVersion: string, productMinorVersion: string) => {
        return LocaleUtils.formatString(text, {
            'productVersion': productVersion,
            'productMinorVersion': productMinorVersion
        });
    };

    const getContent = () => {
        const priceLabel = LocaleUtils.localeOption('pages.buy.priceLabel', appContext.locale);
        const oneYearSubscriptionPrice = getPrice(priceLabel, productInformationItem.oneYearSubscriptionPrice);
        const annualSubscriptionPrice = getPrice(priceLabel, productInformationItem.annualSubscriptionPrice);
        const currentVersionPrice = getPrice(priceLabel, productInformationItem.currentVersionPrice);
        const text = getText(
            LocaleUtils.localeOption('pages.buy.text', appContext.locale),
            productInformationItem.productVersion,
            productInformationItem.productMinorVersion);
        const pageTitle = LocaleUtils.localeOption('pages.buy.title', appContext.locale);

        return <>
            <Helmet>
                <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
            </Helmet>
            <div className="content-section">
                <h1>{pageTitle}</h1>
                <h2>{productInformationItem.productName} {productInformationItem.productVersion}</h2>
                <table style={{border: 'none'}} className={'app-right-padded-table'}>
                    <tbody>
                    <tr>
                        <td>
                            {LocaleUtils.localeOption('pages.buy.oneYearSubscriptionLicenseLabel', appContext.locale)}
                        </td>
                        <td>{oneYearSubscriptionPrice}</td>
                        <td>
                            <a href={productInformationItem.oneYearSubscriptionPayproUrl} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.buy.buyViaPayProLabel', appContext.locale)}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {LocaleUtils.localeOption('pages.buy.annualSubscriptionUpdateLabel', appContext.locale)}
                        </td>
                        <td>{annualSubscriptionPrice}</td>
                        <td>
                            <a href={productInformationItem.annualSubscriptionPayproUrl} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.buy.buyViaPayProLabel', appContext.locale)}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            {LocaleUtils.localeOption('pages.buy.currentVersionLicenseLabel', appContext.locale)}
                        </td>
                        <td>{currentVersionPrice}</td>
                        <td>
                            <a href={productInformationItem.currentVersionPayproUrl} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.buy.buyViaPayProLabel', appContext.locale)}
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ReactMarkdown>{text}</ReactMarkdown>
            </div>
        </>
    };

    return (
        productInformationItem ? getContent() : <PageNotFound/>
    );
}

export default ReactUtils.withParams(Buy);
