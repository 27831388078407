import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { OverviewItem } from '../../common/models/OverviewItem';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import OverviewData from '../../assets/data/overview.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type OverviewProps = {
    params: any;
};

function Overview(props: OverviewProps) {
    const appContext = useContext(ApplicationContext);
    const contextPath = process.env.PUBLIC_URL;
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const overviewData: { [index: string]: any } = OverviewData;
    const overviewItem: OverviewItem = overviewData[camelCasedProduct];
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];
    const pageTitle = LocaleUtils.localeOption('pages.overview.title', appContext.locale);

    return (
        (overviewItem && productInformationItem) ?
            <>
                <Helmet>
                    <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
                </Helmet>
                <div className="content-section">
                    <h1>{pageTitle}</h1>
                    <div className="grid">
                        <div className="col-fixed">
                            <img src={`${contextPath}/images/logo/${overviewItem.imageName}`}
                                 alt={productInformationItem.productName}
                                 className="app-product-logo-image"/>
                        </div>
                        <div className="col md:ml-2">
                            <ReactMarkdown>
                                {LocaleUtils.localeOption(overviewItem.textKey, appContext.locale)}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </> : <PageNotFound/>
    );
}

export default ReactUtils.withParams(Overview);
