import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import { ApplicationContext } from '../../common/ApplicationContext';
import { CommonInformation } from '../../common/models/CommonInformation';
import { ProductInformationItem } from '../../common/models/ProductInformationItem';
import PageNotFound from '../../common/PageNotFound';
import LocaleUtils from '../../utils/LocaleUtils';
import ReactUtils from '../../utils/ReactUtils';
import CommonInformationData from '../../assets/data/commonInformation.json';
import ProductInformationData from '../../assets/data/productInformation.json';

type DownloadProps = {
    params: any;
};

function Download(props: DownloadProps) {
    const appContext = useContext(ApplicationContext);
    const {product} = props.params;
    const camelCasedProduct = _.camelCase(product);
    const productInformationData: { [index: string]: any } = ProductInformationData;
    const productInformationItem: ProductInformationItem = productInformationData[camelCasedProduct];
    const commonInformation: CommonInformation = CommonInformationData.data;
    const downloadDirectory = 'download';

    const getFileName = (fileNameTemplate: string, productVersion: string) => {
        return LocaleUtils.formatString(fileNameTemplate, {'productVersion': productVersion});
    };

    const getFileSize = (fileSizeTemplate: string, fileSize: number) => {
        return LocaleUtils.formatString(fileSizeTemplate, {'fileSize': fileSize}).replace(/ /g, '\u00A0');
    };

    const getContent = () => {
        const windowsDistributionFileName = getFileName(
            productInformationItem.windowsDistributionFileNameTemplate, productInformationItem.productVersion);
        const macosxDistributionFileName = getFileName(
            productInformationItem.macosxDistributionFileNameTemplate, productInformationItem.productVersion);
        const linuxDistributionFileName = getFileName(
            productInformationItem.linuxDistributionFileNameTemplate, productInformationItem.productVersion);
        const commonDistributionFileName = getFileName(
            productInformationItem.commonDistributionFileNameTemplate, productInformationItem.productVersion);

        const fileSizeLabel = LocaleUtils.localeOption('pages.download.fileSizeLabel', appContext.locale);
        const windowsDistributionFileSize = getFileSize(fileSizeLabel, productInformationItem.windowsDistributionFileSize);
        const macosxDistributionFileSize = getFileSize(fileSizeLabel, productInformationItem.macosxDistributionFileSize);
        const linuxDistributionFileSize = getFileSize(fileSizeLabel, productInformationItem.linuxDistributionFileSize);
        const commonDistributionFileSize = getFileSize(fileSizeLabel, productInformationItem.commonDistributionFileSize);

        const anyPlatformTextTemplate = LocaleUtils.localeOption('pages.download.anyPlatformText', appContext.locale);
        const anyPlatformText = LocaleUtils.formatString(anyPlatformTextTemplate,
            {'jreName': commonInformation.jreName, 'javaDownloadsUrl': commonInformation.javaDownloadsUrl});

        const pageTitle = LocaleUtils.localeOption('pages.download.title', appContext.locale);

        return <>
            <Helmet>
                <title>{`${productInformationItem.productShortName} - ${pageTitle}`}</title>
            </Helmet>
            <div className="content-section">
                <h1>{pageTitle}</h1>
                <h2>{productInformationItem.productName} {productInformationItem.productVersion}</h2>
                <table style={{border: 'none'}} className={'app-right-padded-table'}>
                    <tbody>
                    <tr>
                        <td>
                            <ReactMarkdown components={{p: React.Fragment}}>
                                {LocaleUtils.localeOption('pages.download.windowsText', appContext.locale)}
                            </ReactMarkdown>
                        </td>
                        <td>
                            <a href={`/${downloadDirectory}/${windowsDistributionFileName}`} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.download.downloadLabel', appContext.locale)}
                            </a>
                        </td>
                        <td>{windowsDistributionFileSize}</td>
                    </tr>
                    <tr>
                        <td>
                            <ReactMarkdown components={{p: React.Fragment}}>
                                {LocaleUtils.localeOption('pages.download.macosText', appContext.locale)}
                            </ReactMarkdown>
                        </td>
                        <td>
                            <a href={`/${downloadDirectory}/${macosxDistributionFileName}`} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.download.downloadLabel', appContext.locale)}
                            </a>
                        </td>
                        <td>{macosxDistributionFileSize}</td>
                    </tr>
                    <tr>
                        <td>
                            <ReactMarkdown components={{p: React.Fragment}}>
                                {LocaleUtils.localeOption('pages.download.linuxText', appContext.locale)}
                            </ReactMarkdown>
                        </td>
                        <td>
                            <a href={`/${downloadDirectory}/${linuxDistributionFileName}`} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.download.downloadLabel', appContext.locale)}
                            </a>
                        </td>
                        <td>{linuxDistributionFileSize}</td>
                    </tr>
                    <tr>
                        <td colSpan={3}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <ReactMarkdown components={{p: React.Fragment}}>
                                {anyPlatformText}
                            </ReactMarkdown>
                        </td>
                        <td>
                            <a href={`/${downloadDirectory}/${commonDistributionFileName}`} target="_blank"
                               rel="noopener noreferrer">
                                {LocaleUtils.localeOption('pages.download.downloadLabel', appContext.locale)}
                            </a>
                        </td>
                        <td>{commonDistributionFileSize}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    };

    return (
        productInformationItem ? getContent() : <PageNotFound/>
    );
}

export default ReactUtils.withParams(Download);
